<template>
  <div class="bill-check-payment-online">
    <my-nav-bar
      title="线上支付实收"
      left-text="返回"
      left-arrow
    />
    <div class="app-main-container">
      <van-empty v-if="showEmpty" description="暂无数据" />
      <van-list v-model="loading" class="list" :finished="finished" @load="getList">
        <div>
          <payment-online-item v-for="(payment, index) in list" :key="index" :payment="payment" @click="handleShowPaymentQrcode" />
        </div>
      </van-list>
    </div>
    <my-dialog
      v-model="showPaymentQrcodeDialog"
      title="收款码"
      :show-cancel-button="false"
    >
      <div class="payment-qrcode">
        <van-image :src="qrcodeData" />
      </div>
    </my-dialog>
  </div>
</template>

<script>
import MyNavBar from '@/components/my-nav-bar'
import PaymentOnlineItem from './components/payment-online-item'
import { getPaymentOnlines } from '@/api/bill'
import qrcode from 'qrcode'
import MyDialog from '@/components/my-dialog'

export default {
  components: { MyNavBar, PaymentOnlineItem, MyDialog },
  data() {
    return {
      loading: false,
      finished: false,
      showEmpty: false,
      listQuery: {
        page: 0,
        bill_id: this.$route.query.bill_id
      },
      list: [],
      showPaymentQrcodeDialog: false,
      qrcodeData: null
    }
  },
  created() {
    this.getList()
  },
  methods: {
    getList() {
      this.loading = true
      this.listQuery.page++
      getPaymentOnlines(this.listQuery).then(res => {
        this.loading = false
        this.list = this.list.concat(res.data.list)
        this.showEmpty = this.list.length === 0
        this.finished = res.data.list.length < 10
      }).catch(() => {
        this.loading = false
        this.finished = true
        this.showEmpty = true
      })
    },
    handleShowPaymentQrcode(payment) {
      const url = process.env.VUE_APP_PAYMENT_URL + '?order_num=' + payment.order_num
      qrcode.toDataURL(url, { width: 200 }, (err, data) => {
        if (err) {
          this.fail('生成付款二维码失败')
          console.log(err)
          return
        }
        this.showPaymentQrcodeDialog = true
        this.qrcodeData = data
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .list {
    padding: 10px;
  }
  .list .payment-online-item:nth-last-child(1) {
    margin-bottom: 0!important;
  }
  .payment-qrcode {
    text-align: center;
  }
</style>
